import React from "react";
import logo from "./lcexpress_cut_trans.png";
import banner from "./banner.png";
import dhl from "./dhlIcon.png";
import { CNavLink } from "@coreui/react";
import { t } from "i18next";
import LangSwitcher from "src/components/header/LangSwitcher";
import LandingPageTracking from "src/views/Order/LandingPageTracking";
export const Nav30DataSource = {
  wrapper: { className: "header3 home-page-wrapper jzih1dpqqrg-editor_css" },
  page: { className: "home-page" },
  logo: {
    className: "header3-logo jzjgnya1gmn-editor_css",
    children: logo,
    // "https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*-J8NSLj9rbsAAAAAAAAAAABkARQnAQ",
  },
  Menu: {
    className: "header3-menu",
    children: [
      {
        name: "item2",
        className: "header3-item",
        children: {
          href: "#",
          children: [
            {
              children: (
                <span>
                  <CNavLink href={"/#/login"}>{t("login")}</CNavLink>
                </span>
              ),
              name: "text",
            },
          ],
        },
      },
    ],
  },
  mobileMenu: { className: "header3-mobile-menu" },
};
export const Banner50DataSource = {
  wrapper: { className: "home-page-wrapper banner5", style: { height: 730 } },
  page: { className: "home-page banner5-page", style: { height: 730 } },
  childWrapper: {
    className: "banner5-title-wrapper",
    children: [
      {
        name: "title",
        children: (
          <span>
            <p>L&amp;C Express</p>
            <p>
              <br />
            </p>
          </span>
        ),
        className: "banner5-title",
      },
      {
        name: "explain",
        className: "banner5-explain",
        children: (
          <span>
            <p>
              Đơn vị có nhiều năm kinh nghiệm trong lĩnh vực&nbsp;
              <span>chuyển phát quốc tế tại Việt Nam</span>
            </p>
          </span>
        ),
      },
      {
        name: "content",
        className: "banner5-content",
        children: (
          <span>
            <span>
              <span>
                <p>
                  L&amp;C Express mang tới cho Quý khách giải pháp vận chuyển
                  linh hoạt và nhanh nhất đến bất kỳ nơi nào trên toàn thế giới,
                  với thời gian được xác định trước. Không giới hạn về trọng
                  lượng và kích thước, dịch vụ này đáp ứng được hầu hết các yêu
                  cầu đặc biệt của Quý khách như: bảo hiểm hàng gửi, bảo đảm
                  phát vào thời gian xác định trong ngày tại các thành phố chính
                  trên thế giới,…
                  <br />
                </p>
              </span>
            </span>
          </span>
        ),
      },
    ],
  },
  image: {
    className: "banner5-image",
    children: banner,
  },
};
export const Content50DataSource = {
  wrapper: { className: "home-page-wrapper content5-wrapper" },
  page: { className: "home-page content5" },
  OverPack: { playScale: 0.3, className: "" },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "title",
        children: (
          <span>
            <p>Đối tác của chúng tôi</p>
          </span>
        ),
        className: "title-h1",
      },
      {
        name: "content",
        className: "title-content",
        children: (
          <span>
            <p>
              <br />
            </p>
          </span>
        ),
      },
    ],
  },
  block: {
    className: "content5-img-wrapper",
    gutter: 16,
    children: [
      {
        name: "block0",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          img: {
            children: dhl,
          },
          content: { children: "DHL" },
        },
      },
      {
        name: "block1",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          img: {
            children:
              "https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/125_Fedex_logo_logos-512.png",
          },
          content: { children: "FedEx" },
        },
      },
      {
        name: "block2",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          img: {
            children:
              "https://www.ups.com/assets/resources/webcontent/images/ups-logo.svg",
          },
          content: { children: "UPS" },
        },
      },
      // {
      //   name: "block3",
      //   className: "block",
      //   md: 6,
      //   xs: 24,
      //   children: {
      //     wrapper: { className: "content5-block-content" },
      //     img: {
      //       children:
      //         "https://zos.alipayobjects.com/rmsportal/faKjZtrmIbwJvVR.svg",
      //     },
      //     content: { children: "Ant Motion" },
      //   },
      // },
      // {
      //   name: "block4",
      //   className: "block",
      //   md: 6,
      //   xs: 24,
      //   children: {
      //     wrapper: { className: "content5-block-content" },
      //     img: {
      //       children:
      //         "https://t.alipayobjects.com/images/rmsweb/T11aVgXc4eXXXXXXXX.svg",
      //     },
      //     content: { children: "Ant Design" },
      //   },
      // },
      // {
      //   name: "block5",
      //   className: "block",
      //   md: 6,
      //   xs: 24,
      //   children: {
      //     wrapper: { className: "content5-block-content" },
      //     img: {
      //       children:
      //         "https://zos.alipayobjects.com/rmsportal/faKjZtrmIbwJvVR.svg",
      //     },
      //     content: { children: "Ant Motion" },
      //   },
      // },
      // {
      //   name: "block6",
      //   className: "block",
      //   md: 6,
      //   xs: 24,
      //   children: {
      //     wrapper: { className: "content5-block-content" },
      //     img: {
      //       children:
      //         "https://t.alipayobjects.com/images/rmsweb/T11aVgXc4eXXXXXXXX.svg",
      //     },
      //     content: { children: "Ant Design" },
      //   },
      // },
      // {
      //   name: "block7",
      //   className: "block",
      //   md: 6,
      //   xs: 24,
      //   children: {
      //     wrapper: { className: "content5-block-content" },
      //     img: {
      //       children:
      //         "https://zos.alipayobjects.com/rmsportal/faKjZtrmIbwJvVR.svg",
      //     },
      //     content: { children: "Ant Motion" },
      //   },
      // },
    ],
  },
};
export const Feature00DataSource = {
  wrapper: { className: "home-page-wrapper content0-wrapper" },
  page: { className: "home-page content0" },
  OverPack: { playScale: 0.3, className: "" },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "title",
        children: (
          <span>
            <p>Dịch vụ được L&amp;C Express cung cấp bao gồm:</p>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: "content0-block-wrapper",
    children: [
      {
        name: "block0",
        className: "jzjn8afnsxb-editor_css content0-block",
        md: 6,
        xs: 24,
        children: {
          className: "content0-block-item jzjgrrupf2c-editor_css",
          children: [
            {
              name: "image",
              className: "content0-block-icon jzjgrlz134-editor_css",
              children:
                "https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*CTp8T7RT-VkAAAAAAAAAAABkARQnAQ",
            },
            {
              name: "title",
              className: "content0-block-title jzj8xt5kgv7-editor_css",
              children: (
                <span>
                  <span>
                    <span>
                      <p>Hàng Hoá và Chứng Từ</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: "content",
              children: (
                <span>
                  <span>
                    <p>
                      Chuyển phát hỏa tốc hàng hóa và chứng từ
                      <br />
                      Chuyển phát tiết kiệm hàng hóa
                    </p>
                  </span>
                </span>
              ),
              className: "jzj8z9sya9-editor_css",
            },
          ],
        },
      },
      {
        name: "block1",
        className: "content0-block",
        md: 6,
        xs: 24,
        children: {
          className: "content0-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-icon jzjncn210ql-editor_css",
              children:
                "https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*CTp8T7RT-VkAAAAAAAAAAABkARQnAQ",
            },
            {
              name: "title",
              className: "content0-block-title jzjne54fwqm-editor_css",
              children: (
                <span>
                  <span>
                    <span>
                      <p>Chuyên Tuyến</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: "content",
              children: (
                <span>
                  <span>
                    <span>
                      <p>
                        Dịch vụ vận chuyển chuyên tuyến đi China
                        <br />
                      </p>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: "block2",
        className: "content0-block",
        md: 6,
        xs: 24,
        children: {
          className: "content0-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-icon jzjndq0dueg-editor_css",
              children:
                "https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*CTp8T7RT-VkAAAAAAAAAAABkARQnAQ",
            },
            {
              name: "title",
              className: "content0-block-title jzjne24af8c-editor_css",
              children: (
                <span>
                  <p>Nhanh Chóng</p>
                </span>
              ),
            },
            {
              name: "content",
              children: (
                <span>
                  <p>Chuyển phát nhanh hẹn giờ</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: "block~jzjn87bmyc7",
        className: "content0-block",
        md: 6,
        xs: 24,
        children: {
          className: "content0-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-icon jzjndsyw8sf-editor_css",
              children:
                "https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*CTp8T7RT-VkAAAAAAAAAAABkARQnAQ",
            },
            {
              name: "title",
              className: "content0-block-title jzjndw5oerk-editor_css",
              children: (
                <span>
                  <p>Hàng Không</p>
                </span>
              ),
            },
            {
              name: "content",
              children: (
                <span>
                  <p>Dịch vụ vận chuyển hàng không, thông quan thuê.</p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Footer10DataSource = {
  wrapper: { className: "home-page-wrapper footer1-wrapper" },
  OverPack: { className: "footer1", playScale: 0.2 },
  block: {
    className: "home-page",
    gutter: 0,
    children: [
      {
        name: "block3",
        xs: 24,
        md: 12,
        className: "block",
        title: {
          children: (
            <span>
              <p>L&amp;C EXPRESS TRANSPORT CO., LTD</p>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              href: "#",
              name: "link0",
              children: (
                <span>
                  <span>
                    <p>
                      Địa chỉ:&nbsp;105 Nguyen Xien, Ha Dinh, Thanh Xuan, Ha Noi
                    </p>
                  </span>
                </span>
              ),
            },
            {
              href: "#",
              name: "link1",
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>Mobile 1: (+84) 0243 201 8309</p>
                        <p>Mobile 2: (+84) 0981 633 639</p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: "copyright-wrapper" },
  copyrightPage: { className: "home-page" },
  copyright: {
    className: "copyright",
    children: (
      <span>
        <span>
          <br />
        </span>
      </span>
    ),
  },
};
